.page-error-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 65px 0px 0px 0px;
  justify-content: center;
}

.page-error-code {
  color:rgba(0, 0, 0, 0.7);
  font-size: calc(var(--font-size-multiplier) * 44px);
  font-weight: 700;
}

.page-error-message {
  color:rgba(0, 0, 0, 0.7);
  font-weight: 600;
  margin-top: 32px;
  font-size: calc(var(--font-size-multiplier) * 35px);
  margin-bottom: 30px;
  text-align: center;
}
.page-error-message-bottom {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.light {
  display: flex;
  flex-direction: column;
  font-size: calc(var(--font-size-multiplier) * 19px);
}

.blue {
  text-decoration: underline;
  color: #00ccf5;
  cursor: pointer;
  margin: 0px;
  margin-left: 4px;
}

.page-error-message-modal-container {
  display: flex;
  flex-direction: row;
}
