.container {
  font-family: "Montserrat";
  font-weight: 600;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.results-container {
  border: 1px solid #f4f4f6;
  padding: 10px 30px;
}

.item {
  display: flex;
  flex-direction: column;
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  padding-bottom: 30px;
  margin-top: 15px;
}

.title {
  font-size: calc(var(--font-size-multiplier) * 23px) !important;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 13px;
}

.title-inactive {
  font-size: calc(var(--font-size-multiplier) * 23px) !important;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 10px;
}

@media (max-width: 780px) {
  .container {
    width: 100%;
  }
}
