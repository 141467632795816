.label {
  height: fit-content;
  width: fit-content;
  background: #4d4f50;
  background-color: #4d4f50;
  border-radius: 5px;
  border-color: #4d4f50;
  border-bottom: 0px 4px 0px 0px #309dbc;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 20px;
}

.label:hover {
  background-color: #4d4f50;
}

.label .font {
  font-size: calc(var(--font-size-multiplier) * 11pt);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.font {
  color: white;
  margin: 0;
}

.label .label-catalog-font {
  font-weight: 600;
  text-align: left;
  font-size: calc(var(--font-size-multiplier) * 14px);
}

.label-tag-close {
  filter: none;
}

.label-tag-close:hover {
  /* red filter */
  filter: invert(1) invert(87%) sepia(13%) saturate(455%) hue-rotate(314deg) brightness(94%) contrast(93%);
}

.icon-close {
  width: 21px;
  height: 21px;
  fill: white;
  cursor: pointer;
  margin-left: 10px;
}
