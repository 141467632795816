.profile-main-left-linkform-link-text {
  font-size: calc(var(--font-size-multiplier) * 23px) !important;
  color: #00ccf5;
  text-decoration: underline;
  font-size: calc(var(--font-size-multiplier) * 22px) !important;
}
@media (width < 420px) {
  .profile-main-left-linkform-link-text {
    font-size: calc(var(--font-size-multiplier) * 22px) !important;
  }
}
.profile-main-left-linkform-link-icon-blue {
  width: 27px;
  height: 18px;
  margin-right: 15px;
  filter: invert(65%) sepia(31%) saturate(4246%) hue-rotate(147deg)
    brightness(99%) contrast(104%);
}

@media (width < 420px) {
  .profile-main-left-linkform-link-icon {
    width: 23px;
    height: 14px;
    margin-right: 11px;
    filter: invert(27%) sepia(24%) saturate(4487%) hue-rotate(327deg)
      brightness(80%) contrast(89%);
  }
}

.profile-main-left-linkform-link-red-text {
  font-size: calc(var(--font-size-multiplier) * 22px) !important;
  color: #bb3540;
  text-decoration: underline;
}
@media (width < 420px) {
  .profile-main-left-linkform-link-red-text {
    font-size: calc(var(--font-size-multiplier) * 22px) !important;
  }
}

.profile-main-left-linkform-link-red-icon {
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

.profile-main-left-linkform-link {
  display: flex;
  align-items: center;
}

.three-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  border: 2px solid #f4f4f6;
  padding: 40px 40px;
  margin-bottom: 60px;
}

@media (width < 780px) {
  .three-columns {
    width: 393px;

    align-items: flex-start;
    padding: 15px 28px;
  }
}

.column {
  display: "flex";

  justify-content: center;
  align-items: center;
  flex: 0 0 calc(33.33% - calc(var(--font-size-multiplier) * 20px) important);
}
.column h2 {
  font-size: calc(var(--font-size-multiplier) * 26px) !important;
  margin-bottom: 0px;
}

@media (width < 780px) {
  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.column h2 {
  font-size: calc(var(--font-size-multiplier) * 27px);
  margin-bottom: 33px;
}

.column-h2 {
  font-size: calc(var(--font-size-multiplier) * 27px);
}

.box-field {
  font-size: calc(var(--font-size-multiplier) * 19px);
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (width < 1260px) {
  .box-field {
    font-size: calc(var(--font-size-multiplier) * 15px) !important;
  }
}
@media (width < 780px) {
  .box-field {
    display: flex;
    flex-direction: column;
    height: 40px;
    margin-top: 5px;
    align-items: flex-start;
  }
}

.box-icon {
  margin-right: 1em;
  width: 20px;
  opacity: 0.6;
}

.box-field-title {
  margin-right: 10px;
}

@media screen and (max-width: 820px) {
  .column {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}
