.box-kopas {
  font-family: "Montserrat", Semi Bold;
  flex-direction: column;
  border: 1px solid #f4f4f6;
  padding: 0px 0px;
  width: 100%;
}

.h1 {
  font-weight: bold;
  font-size: calc(var(--font-size-multiplier) * 33px);
}

.close-rules-white {
  filter: invert(71%) sepia(63%) saturate(4251%) hue-rotate(150deg)
    brightness(89%) contrast(98%);
}

.overall-title {
  font-size: calc(var(--font-size-multiplier) * 26px);
  font-weight: 600;
}

.box-url {
  font-size: calc(var(--font-size-multiplier) * 19px) !important;
  font-weight: 600;
  color: "lightblue";
  max-width: 400px;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

@media (width < 780px) {
  .box-url {
    font-size: calc(var(--font-size-multiplier) * 16px) !important;
    padding-bottom: 20px;
  }
}

@media (width < 400px) {
  .box-url {
    width: 200px;
  }
}

.header-container {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  border-bottom: 4px solid #f4f4f6;
}

.header-container div:first-child {
  height: 80px;
  width: 60%;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}

.header-container div:last-child {
  height: 80px;
  flex: 1;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}

.header-container div {
  border: none;
  cursor: pointer;
  padding-top: 25px;
  text-align: center;
}

.header-container div.active {
  background-color: #f4f4f6;
}

@media (width < 780px) {
  .header-container {
    align-items: center;
    font-size: calc(var(--font-size-multiplier) * 16px) !important;
  }
  .header-container div:first-child {
    width: 50%;
    padding-top: 20px;
    text-align: center;
  }
  .header-container div:last-child {
    text-align: center;
    padding-top: 20px;
  }
}

.steps-label-description-span {
  font-size: calc(var(--font-size-multiplier) * 18px);
  font-weight: 600;
  color: var(--c_light-gray);
}

.content-add-kopa {
  padding: 2.5em;
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
}

@media (width < 780px) {
  .content-add-kopa {
    padding: 1em;
  }
}

.content-title-add-kopa {
  font-size: 32px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.content-title-h1 {
  font-size: calc(var(--font-size-multiplier) * 33px);
  font-weight: 600;
  font-family: Montserrat, Semi Bold;
}

.content-subtitle-add-kopa {
  font-size: calc(var(--font-size-multiplier) * 20px) !important;
}
.results-container .title {
  font-weight: 600;
  color:#4d4f50
}

.results-container .title:hover .arrow {
  /* convert color to blue on title hover */
  filter: invert(50%) sepia(200%) saturate(2000%) hue-rotate(180deg)
    brightness(100%) contrast(100%);
}
/* OVERALL DATA */

.overall-container {
  border-top: 3px solid #f4f4f6;
  padding-top: 2em;
  margin-top: 2em;
}

.overall-databox {
  padding: calc(2em - 20px) 2em 2em 2em;
  font-size: calc(var(--font-size-multiplier) * 18px);
  background-color: #f4f4f6;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.input-component { /* Label + Input */
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 2em;
}

.input-wrapper {
  position: relative;
  width: 70%;
}

.overall-databox .input-wrapper {
  width: calc(70% + (0.7 * 4em));
  min-width: 0px;
}

.overall-data-box-header {
  font-size: calc(var(--font-size-multiplier) * 17px);
  font-weight: 600;
  color: var(--c_light-gray);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.overall-data-box-header-date {
  display: flex;
  color: var(--c_light-gray-transparent);
  text-transform: none;
  font-weight: 500;
  gap: 0.5em;
}

@media (width < 780px) {
  .overall-databox {
    padding: 20px 20px;
  }
}

.overall-databox-label {
  font-size: calc(var(--font-size-multiplier) * 18px);
  font-weight: 500;
  color: var(--c_light-gray);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.overall-databox-devider {
  border-top: 3px solid rgba(77, 79, 80, 0.2);
  margin: 20px 0;
}

.overall-input-container {
  height: 60px;
}

.overall-input-icon {
  margin-left: 15px;
  width: 16px;
  height: 16px;
  filter: invert(59%) sepia(58%) saturate(2776%) hue-rotate(149deg)
    brightness(106%) contrast(102%);
}

.overall-input-icon-grey {
  margin-left: 18px;
  width: 16px;
  height: 16px;
  filter: invert(88%) sepia(7%) saturate(712%) hue-rotate(202deg)
    brightness(112%) contrast(93%);
}

.overall-input {
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  padding: 20px 20px;
  margin-top: 20px;
  width: 523px;
  border-radius: 5px;
  border: 3px solid rgba(77, 79, 80, 0.2);
  height: 20px;
}
.overall-input-big {
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  padding: 20px 20px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(77, 79, 80, 0.2);
  height: 189px;
  resize: none;
  border-radius: 3px;
}

.overall-input-big:hover {
  outline-color: #00ccf5;
  outline-style: solid;
  outline-width: 4px;
}

.overall-input-big:placeholder {
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
}

.overall-input::placeholder {
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  color: rgba(77, 79, 80, 0.2);
}

.overall-upload-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.overall-upload-container-with-delete:last-child {
  margin-left: auto;
}

@media (width < 780px) {
  .overall-upload-container {
    display: flex;
    flex-direction: column;
  }
}

.overall-image-upload-svg {
  rotate: 90deg;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
}

.overall-file-upload-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.overall-file-upload-text {
  font-size: calc(var(--font-size-multiplier) * 17px);
  color: var(--c_light-gray-transparent);
}

.overall-file-uploaded-text-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.overall-file-upload-text-filename:hover {
  text-decoration: underline;
}

.overall-file-upload-text-filename {
  font-weight: 600;
  color: var(--c_light_blue);
  word-break: break-all;
  cursor: pointer;
}
.overall-file-upload-text-filedescription {
  font-weight: 500;
  color: var(--c_light-gray-transparent);
  word-break: break-all;
}

.overall-file-upload-text-filedescription-description {
  margin-left: 1em;
  color: var(--c_light-gray);
}


@media (width < 780px) {
  .overall-file-upload-text {
    font-size: calc(var(--font-size-multiplier) * 16px) !important;
    margin-top: 15px;
  }
}

.default-option {
  color: var(--c_light-gray);
}

.overall-input-dropdown {
  width: 100%;
  padding: 5px 20px;
  padding-right: 40px;
  font-family: "Montserrat", Semi Bold;
  font-size: calc(var(--font-size-multiplier) * 17px);
  height: 45px;
  border-radius: 5px;
  -webkit-appearance: none;
  border: 2px solid rgba(77, 79, 80, 0.2);
}

.overall-input-dropdown-error {
  width: 100%;
  padding: 5px 20px;
  padding-right: 40px;
  font-family: "Montserrat", Semi Bold;
  font-size: calc(var(--font-size-multiplier) * 17px);
  height: 45px;
  border-radius: 5px;
  -webkit-appearance: none;
  border: 2px solid rgba(77, 79, 80, 0.2);

  color: var(--c_error);
  outline-color: var(--c_error_outline);
  outline-style: solid;
  outline-width: 4px;
}

.overall-input-dropdown:hover {
  outline-color: #00ccf5;
  outline-style: solid;
  outline-width: 4px;
  opacity: 0.8;
}

.overall-input-dropdown:focus {
  outline-color: #00ccf5;
  outline-style: solid;
  outline-width: 4px;
  opacity: 0.8;
}

@media (width < 900px) {
  .overall-input-dropdown {
    width: 90%;
  }
}

.dropdown-arrow {
  position: absolute;
  rotate: 180deg;

  width: 16px;
  height: 16px;
  pointer-events: none;
  filter: invert(30%) sepia(0%) saturate(455%) hue-rotate(251deg)
    brightness(94%) contrast(87%);
  transform: translate(30px, -12px);
  right: -16px;
}
.selected-option {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: calc(var(--font-size-multiplier) * 14px) !important;
  font-family: "Montserrat", Bold;
  font-weight: 600;
  margin-top: 14px;
  padding-left: 21px;
  padding-right: 21px;
  height: 32px;
  background-color: #4d4f50;
  margin-right: 10px;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  z-index: 1;
}

.selected-option-icon {
  width: 16px;
  height: 16px;
  margin-left: 11px;
}

.overall-file-upload-date {
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  color: rgba(77, 79, 80, 0.2);
  height: 100%;
  padding-top: 10px;
  padding-left: 20px;
}

@media (width < 780px) {
  .overall-file-upload-date {
    padding-left: 0px;
  }
}

.overall-data-file-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (width < 780px) {
  .overall-data-file-name {
    flex-direction: column;
    align-items: flex-start;
    font-size: calc(var(--font-size-multiplier) * 16px) !important;
    margin-left: 0px;
  }
}

.overall-sub-box {
  border-top: 3px solid #f4f4f6;
  padding-right: 30px;
  font-size: calc(var(--font-size-multiplier) * 18px) !important;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Montserrat", Semi Bold;
  font-weight: 600;
}

@media (width < 780px) {
  .overall-sub-box {
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }
}
