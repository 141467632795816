/* custom-datetime.css */

.rdtPrev,
.rdtNext,
.rdtYear,
.rdtMonth,
.rdtSwitch,
.rdtDay {
    border-radius: 7px;
}

.rdtPicker .rdtOld {
    color: rgba(51, 51, 51, 0.2) !important
}

.rdtPicker .dow {
    color: rgba(51, 51, 51, 0.9);
    font-weight: 400;
}
.rdtPicker td {
    color: rgb(51, 51, 51);
    font-weight: 500;
}

.rdtPicker .rdtActive {
    color: white;
    background-color: var(--c_light_blue) !important;
    transition: background-color 0.2s;
}

.rdtPicker .rdtMonth:hover,
.rdtPicker .rdtDays td:hover {
    background-color: var(--c_lighter_blue) !important;
    transition: background-color 0.2s;
}

.rdtPicker td.rdtActive:hover {
    color: white;
    background-color: var(--c_slightly_lighter_blue) !important;
    transition: background-color 0.2s;
}

.rdtPicker .rdtDays .rdtNew:hover,
.rdtPicker .rdtDays .rdtOld:hover {
    color: gray;
    background-color: var(--c_lighter_blue) !important;
    transition: background-color 0.2s ease-in-out;
}

.rdtPicker td,
.rdtPicker th {
    height: 45px !important;
}

.rdtPicker {
    width: 370px !important;
}

.rdtTimeToggle:hover {
    border-radius: 7px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: calc(var(--font-size-multiplier) * 28px);
    vertical-align: middle !important;
}

.rdtToday::before {
    border-left-width: 10px !important;
    border-bottom-width: 10px !important;
    border-bottom-color: var(--c_light_blue) !important;
}

.rdtActive.rdtToday::before {
    border-bottom-color: white !important;
}