/* Styles for the parent Component */
.sort-order-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.sort-order-btn:hover {
  transform: scale(1.1);
  background-color: rgba(0,0,0,0.01);
  box-shadow: -2px 3px 1rem 1px rgba(90, 97, 129, 0.15);
}

.filter-dropdown {
  position: relative;
  width: 100%;
  min-width: 0;
  outline: 4px solid transparent;
  border-radius: 4px;
  outline: 2px solid var(--c_input_border);
  cursor: pointer;
}

.catalog-main-sort-group {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.filter-calendar-container {
  position: relative;
}
.filter-calendar-container * {
  font-size: calc(var(--font-size-multiplier) * 17px);
}
.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__input {
  height: auto;
}

.react-calendar.react-calendar,
.filter-calendar .react-datetime-picker__wrapper {
  outline: 2px solid var(--c_input_border);
  border: none;
  border-radius: 4px;
  padding: 4px;
}
.filter-calendar {
  width: 100%;
}
.filter-calendar .react-datetime-picker__inputGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter-calendar .react-datetime-picker__inputGroup__input:invalid {
  background: none;
}
.filter-calendar svg:hover {
  color: #00CCF5;
}

.filter-calendar .react-calendar__tile--active {
  background: #00CCF5;
}
.filter-calendar .react-calendar button {
  border-radius: 4px;
  padding: 8px 3px;
}
.react-calendar .react-calendar__month-view__weekdays__weekday abbr {
  font-size: 0;
  display: inline-block;
  width: 0;
}
.react-calendar .react-calendar__month-view__weekdays__weekday abbr::first-letter  {
  font-size: calc(var(--font-size-multiplier) * 17px);
  visibility: visible;
  margin-left: -0.5em;
}

.date-filter-dropdown-label {
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
  color: #4d4f50;
}

.filter-dropdown.active {
  outline: 4px solid #33d6f7;
  border-color: #717273;
  background-color: white;
}

.filter-dropdown:hover {
  border-color: #717273;
}

/* Dropdown button */
.filter-dropdown-btn {
  cursor: pointer;
  font-size: calc(var(--font-size-multiplier) * 17px) !important; ;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  color: #4d4f50;
  border: none;
  width: 100%;
  align-items: center;
  border-radius: 6px;

}

.filter-dropdown-btn > div {
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

@media (max-width: 780px) {
  .filter-dropdown-btn {
    margin-right: 0px;
    margin-left: 0px;
  }
  .catalog-main-sort-group {
    gap: 0.25em;
  }
}

.filter-dropdown-btn .icon {
  padding-left: 15px;
  padding-right: 11px;

  background-repeat: no-repeat;
  background-size: 14px 22px;
  background-position-x: 4px;
  filter: invert(0.4);
}


.filter-arrow {
  width: 20;
  height: 20;
  display: inline-block;
  margin-left: 0.3em;
  margin-right: 0.3em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  align-self: center;
}
.filter-dropdown-btn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  align-self: center;
}

/* Toggle button text between title and value */
.filter-dropdown span.filter-dropdown-value {
  display: inline;
  text-align: center;
  margin-left: 5px;
}

.filter-dropdown span.filter-dropdown-label {
  color: #bababa;
  display: none;
  font-size: calc(var(--font-size-multiplier) * 17px) !important; ;
}

.filter-dropdown.active span.filter-dropdown-value {
  display: none;
}

.filter-dropdown.active span.filter-dropdown-label {
  display: inline;
}

/* Dropdown menu */
.filter-dropdown ul {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1;
  border-radius: 0px 0px 4px 4px;
  outline: 2px solid var(--c_input_border);
}

.filter-dropdown:hover ul {
  border-color: #717273;
}

.filter-dropdown.active ul {
  display: block;
}

.filter-dropdown-menu {
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow-y: auto;
  background-color: white;
  display: none;
}

/* Dropdown meun item */
.filter-dropdown-menu li {
  border-top: 2px solid #f4f4f6;
  padding: 7px 7px 7px 1em;
  font-size: calc(var(--font-size-multiplier) * 17px) !important; ;
  list-style: none;
  color: #4d4f50;
  min-height: 41px;
  cursor: pointer;
}

.filter-dropdown-menu li:hover {
  background-color: #f4f4f6;
  z-index: 1;
}

.filter-dropdown-menu .filter-dropdown-menu-item-selected {
  color: white;
  background-color: var(--c_light_blue);
}

.filter-dropdown-menu .filter-dropdown-menu-item-selected:hover {
  background-color: var(--c_slightly_lighter_blue);
}

.catalog-main-filter-dropdown-icon {
  filter: invert(80%) sepia(89%) saturate(0%) hue-rotate(221deg) brightness(50%)
    contrast(94%);
}

.register-info-right-rules-upload span {
  color: #4d4f50;
}

.register-info-right-rules-checkbox {
  color: #4d4f50;
}
