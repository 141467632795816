.font-size-adjust {
  font-size: calc(1rem * var(--font-size-multiplier));
}

.high-contrast {
  background: none;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.high-contrast {
  caret-color: var(--foreground-color);
}

.high-contrast .header-setting-down-auth-personal-container2-button,
.high-contrast .card-content-left-owner-subscribe .card-content-owner-subscribe-button,
.high-contrast .search-load-more-button,
.high-contrast .header-setting-down-auth-button {
  background: var(--foreground-color);
  color: var(--background-color);
  box-shadow: none;
}

.high-contrast .overall-input-dropdown__indicator-separator {
  background-color: var(--foreground-color);
}

.high-contrast .klusti-main-left-sub1-tb th {
  background: var(--background-color) !important;
  box-shadow: none;
}

.high-contrast .register-info-right-cards-item:hover,
.high-contrast .register-info-right-last-button,
.high-contrast .search-load-more-button:hover,
.high-contrast .card-content-owner-subscribe-button:hover,
.high-contrast .header-setting-down-auth-button:hover {
  background: var(--background-color);
  color: var(--foreground-color);
  box-shadow: 0 0 1px var(--foreground-color);
}

.high-contrast .header-setting-down-auth-personal-container2-button p,
.high-contrast .header-setting-down-auth-button-text,
.high-contrast .searchbar-box-closed,
.high-contrast .authorize button:hover span {
  color: var(--background-color);
}

.high-contrast .header-setting-down-auth-button .header-setting-down-auth-button-icon {
  filter: invert()  grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

/* .high-contrast .header-setting-down-auth-button:hover .header-setting-down-auth-button-icon {
  filter: invert() grayscale(100%) contrast(100%) invert() sepia(200%) hue-rotate(0deg) saturate(2000%);

} */

.high-contrast .data-cat-construction-button:hover,
.high-contrast .state,
.high-contrast .catalog-main-filter-result,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover,
.high-contrast .authorize-main-left-alert,
.high-contrast .navbar-menus-tab-title,
.high-contrast .slidebar-map-container,
.high-contrast .register-info-right-profile-info-input .authorize-main-left-alert,
.high-contrast .navbar-menus {
  background-color: var(--background-color);
}

.high-contrast .navbar-menus-tab-content {
  z-index: 10;
}

.high-contrast .catalog-search {
  background: none;
}

.high-contrast .red-error-message,
.high-contrast .tos-div,
.high-contrast .authorize-main-left-alert-text,
.high-contrast .alert-main,
.high-contrast .tos-div div,
.high-contrast .tos-modal,
.high-contrast .palidzibas-main-right-width h3,
.high-contrast .palidzibas-main-right-width p,
.high-contrast .palidzibas-main-right-width strong,
.high-contrast .register-info-right-cards button:hover,
.high-contrast .searchbar-box,
.high-contrast .card-content-left-owner-subscribe .card-content-owner-subscribe-button:hover,
.high-contrast .data-cat-construction-background,
.high-contrast .veik-modal-container,
.high-contrast .veik-modal,
.high-contrast .input-group .label,
.high-contrast .input-group .label p,
.high-contrast .results-container strong,
.high-contrast .card-content-detail-tab-tabpane-header-description,
.high-contrast .navbar-menus-tab-imgs-item {
  background: none;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.high-contrast .radio-text,
.high-contrast .data-provide-content-item-background-text,
.high-contrast .data-cat-construction-background-text {
  text-shadow: none;
}

.high-contrast .manaskopas-main-sub5-font,
.high-contrast .manaskopas-main-tb1-tr2-td2-btn,
.high-contrast .manaskopas-main-tb2-tr2-td2-btn,
.high-contrast .authorize-main-left-reg:hover span,
.high-contrast .manaskopas-main-tb1-tr2-td2-btn-font,
.high-contrast .card-content-detail-tab-tabpane-content-format-tag:hover,
.high-contrast .navbar-menus-tab-imgs button:hover,
.high-contrast .env-dropdown-menu li:hover,
.high-contrast .navbar-menus-tab-content button:hover,
.high-contrast .register-info-right-next-profile-button-disabled,
.high-contrast .navbar-menus .navbar-menus-tab .navbar-menus-tab-title:hover,
.high-contrast .palidzibas-main-accordion-description-subtitle:hover,
.high-contrast .navbar-menus-tab:hover .navbar-menus-tab-title,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-description-info-cat,
.high-contrast .card-content-left-owner-unsubscribe button,
.high-contrast .palidzibas-main-accordion-description-subdescription:hover,
.high-contrast .lang-dropdown-menu li:hover {
  background-color: var(--foreground-color);
  color: var(--background-color);
}

.high-contrast .palidzibas-main-right a:hover,
.high-contrast .token-expired-modal-link:hover,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover span {
  /* reset filter */
  filter: none;
}

.high-contrast .input-group .label,
.high-contrast .card-content-detail-tab-tabbutton-tablink {
  border: 1px solid var(--foreground-color);
}

.high-contrast .navbar-menus-tab-content button,
.high-contrast .env-dropdown-menu li,
.high-contrast .lang-dropdown-menu,
.high-contrast .footer-end-font,
.high-contrast .manaskopas-main-tb1-tr2 .api-container,
.high-contrast .default-secondary-button:hover .default-secondary-button-text,
.high-contrast .searchbar-box input,
.high-contrast .btn-danger,
.high-contrast .input-wrapper,
.high-contrast .react-calendar__month-view__days,
.high-contrast .react-calendar__month-view__days__day--neighboringMonth,
.high-contrast .react-calendar__month-view__weekdays__weekday,
.high-contrast .react-calendar__tile,
.high-contrast .react-calendar__navigation__arrow,
.high-contrast .react-calendar,
.high-contrast .react-calendar__navigation button:disabled,
.high-contrast .react-calendar__navigation__label__labelText,
.high-contrast .overall-file-uploaded-text-container span,
.high-contrast .react-calendar__navigation__label,
.high-contrast .react-calendar__navigation button:enabled:hover,
.high-contrast .searchbar-box button {
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.react-calendar__tile:disabled {
  filter: grayscale(80%)

}

.high-contrast .react-calendar__tile--active,
.high-contrast .react-calendar__tile:enabled:hover {
  background-color: var(--foreground-color);
  color: var(--background-color);
}

.high-contrast .submit-button,
.high-contrast .data-cat-construction-button {
  background-color: var(--foreground-color);
  transition: background-color 0.3s ease;
  box-shadow: none;
}

.high-contrast .data-cat-construction-button-font {
  color: var(--background-color);
  transition: color 0.3s ease;
}

.high-contrast .data-cat-construction-button-icon {
  transition: filter 0.3s ease;
}

.high-contrast .data-cat-construction1:hover {
  box-shadow: none;
  border: none;
}

.high-contrast .slidebar-slideshow-dots-dot {
  background-color: var(--background-color);
}

.high-contrast .active-dot {
  background-color: var(--foreground-color);
}

.high-contrast .register-info-right-cards-item,
.high-contrast .catalog-search,
.high-contrast .iesutne-dialog-content-de-label,
.high-contrast .veik-modal,
.high-contrast .register-info-left-steps-item-icon-circle,
.high-contrast .submit-button:hover,
.high-contrast .filter-calendar-container,
.high-contrast .abonetaskopas-status,
.high-contrast .navbar-search-input input {
  color: var(--foreground-color);
  background-color: var(--background-color);
  outline: 1px solid var(--foreground-color);
  border: none;
}

.high-contrast .navbar-menus {
  outline: 1px solid var(--foreground-color);
  border-radius: 5px;
}

.high-contrast .register-info-right-rules-checkbox-icon:hover,
.high-contrast .register-info-right-cards-item:hover {
  outline: 2px solid var(--foreground-color);
}

.high-contrast .header-setting-down-auth-button:hover,
.high-contrast .card-content-owner-subscribe-button,
.high-contrast .register-info-right-cards button,
.high-contrast .search-modal-button {
  box-shadow: none;
  outline: 1px solid var(--foreground-color);
}

.high-contrast .submit-button,
.high-contrast .register-info-right-last-button:hover,
.high-contrast .palidzibas-main-right-width strong:hover,
.high-contrast .default-secondary-button p,
.high-contrast .catalog-search-button:hover,
.high-contrast .search-modal-button:hover {
  color: var(--background-color);
  background-color: var(--foreground-color);
}

.high-contrast .results-container .item:hover .arrow {
  transform-origin: left center;
  transform: scale(1.1) translateX(2px);
  transition: transform 0.05s ease-out;
}

.high-contrast .catalog-header-left-topic,
.high-contrast .catalog-main-menu-form-text1,
.high-contrast .catalog-main-menu-form-title,
.high-contrast .state-transfer-header-number,
.high-contrast .data-cat-construction-update,
.high-contrast .data-cat-construction-button:hover .data-cat-construction-button-font,
.high-contrast .data-provide-content-item1-link a,
.high-contrast .data-provide-content-item-background-text,
.high-contrast .lang-dropdown-menu li,
.high-contrast .lang-dropdown-value,
.high-contrast .page-error-message,
.high-contrast .footer-middle-container-menus-link,
.high-contrast .overall-dropdown-selection label p,
.high-contrast .catalog-header-right div,
.high-contrast .catalog-header-left-directory div,
.high-contrast .catalog-main-filter-group div,
.high-contrast .catalog-main-filter-option-text,
.high-contrast .catalog-main-filter-title,
.high-contrast .catalog-main-filter-result span,
.high-contrast .catalog-main-filter-link-text,
.high-contrast .header-setting-down-auth-button:hover .header-setting-down-auth-button-text,
.high-contrast .authorize span,
.high-contrast .footer-middle-container-menus-link:hover,
.high-contrast .data-cat-construction-title,
.high-contrast .data-cat-construction-data-text,
.high-contrast .card-content-detail-tab-tabpane-content,
.high-contrast .steps-label-description,
.high-contrast .authorize-main-left-alert-text strong,
.high-contrast .overall-title,
.high-contrast .card-content-detail-tab-tabpane-header-title,
.high-contrast .footer-middle-container-social-mail-info,
.high-contrast .card-content-detail-tab-tabpane-content-format-tag,
.high-contrast .card-content-back,
.high-contrast .authorize-main-left-alert-text,
.high-contrast .overall-data-box-header,
.high-contrast .font-size-adjust div,
.high-contrast .modal-dialog-content-sub2-font3-detail,
.high-contrast .card-content-left-owner,
.high-contrast .palidzibas-main-right-width,
.high-contrast .overall-databox,
.high-contrast .palidzibas-main-right-width span,
.high-contrast .palidzibas-main-right-width a,
.high-contrast .edit-profile-modal-content div,
.high-contrast .edit-profile-modal-content span,
.high-contrast .cant-sub-text,
.high-contrast .content-title-add-kopa,
.high-contrast .authorize-header-title,
.high-contrast .default-secondary-button:hover p,
.high-contrast .profile-main-left-linkform-link span,
.high-contrast .manaskopas-main-tb1-tr2-td1-font,
.high-contrast .register-info-right-rules-checkbox-text,
.high-contrast .ielades-dialog-content-sub1-font2,
.high-contrast .manaskopas-main-tb1-tr2-td3-right-part2-font2,
.high-contrast .register-info-left-steps-item-icon-circle span,
.high-contrast .manaskopas-main-sub3-font,
.high-contrast .palidzibas-main-right li,
.high-contrast .state-transfer-context-text,
.high-contrast .iesutne-dialog-content-scrollbar p,
.high-contrast .header-setting-down-auth-personal-container2-menudrop button p,
.high-contrast .report-error-or-message-popup a,
.high-contrast .updated-rules-log-out p,
.high-contrast .card-content-table tr,
.high-contrast .register-info-right-cards-item-description,
.high-contrast .register-info-right-header,
.high-contrast .register-info-left,
.high-contrast .modal-dialog-content-sub2-font3,
.high-contrast .dashboard-main-form span,
.high-contrast .dashboard-main-form2 span,
.high-contrast .klusti-main-left-sub1-tb,
.high-contrast .klusti-main-left-sub1-tb-tr11-font,
.high-contrast .navbar-menus-tab,
.high-contrast .dashboard-topic-right-top-text,
.high-contrast .header-setting-down-auth-personal span,
.high-contrast .results-container .custom-br-spacing h3,
.high-contrast .results-container .custom-br-spacing p,
.high-contrast .results-container span,
.high-contrast .box-field,
.high-contrast .box-field-value,
.high-contrast .my-profile-title,
.high-contrast .ielades-dialog-content-sub2-font3,
.high-contrast .kontakti-link,
.high-contrast h1,
.high-contrast h2,
.high-contrast h3,
.high-contrast h4,
.high-contrast h5,
.high-contrast h6,
.high-contrast .card-content-detail-tab-tabpane-content-download-item-filename,
.high-contrast .column-h2,
.high-contrast .abonetaskopas-status-font,
.high-contrast .rules-scrollbar strong,
.high-contrast .content-main-left-width strong,
.high-contrast .generic-link,
.high-contrast .manaskopas-main-tb1-tr1 .manaskopas-main-tb1-tr1-th1-font1,
.high-contrast .error-message,
.high-contrast .results-container:hover span,
.high-contrast .ielades-dialog-content-sub2-download-file u,
.high-contrast .catalog-main-filter-link-text:hover,
.high-contrast .register-info-right-rules-scrollbar strong,
.high-contrast .searchbar-box div,
.high-contrast .overall-file-upload-text-filename,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover span,
.high-contrast .user-website,
.high-contrast .overall-data-box-header-date,
.high-contrast .overall-file-upload-text,
.high-contrast .radio-container .search-modal-button,
.high-contrast .register-info-right-profile-info-description::before,
.high-contrast .register-info-right-rules-scrollbar p,
.high-contrast .card-content-detail-tab-tabpane-content-download-item-date,
.high-contrast .catalog-main-menu-form-text {
  color: var(--foreground-color);
}

.high-contrast .register-info-right-rules-scrollbar {
  scrollbar-color: var(--foreground-color) var(--background-color);
}

.high-contrast .item .title:hover {
  color: var(--foreground-color);
  transform: scale(1.01) translateX(7px);
}

.high-contrast .catalog-main-filter-link:hover,
.high-contrast .catalog-search-button:hover .catalog-search-button-text,
.high-contrast .footer-middle-container-menus-link:hover {
  filter: none !important;
}

.high-contrast .edit-profile-modal-content,
.high-contrast .rate-modal,
.high-contrast .card-content-left-owner div,
.high-contrast .card-content-back,
.high-contrast .overall-databox,
.high-contrast .navbar-search-input-box,
.high-contrast .overall-input-dropdown-error,
.high-contrast .overall-input-dropdown,
.high-contrast .manaskopas-main tr,
.high-contrast .navbar-search-input,
.high-contrast .overall-input-dropdown option,
.high-contrast .manaskopas-main-sub5-btn5:hover .manaskopas-main-sub5-font,
.high-contrast .searchbar-box-input-container,
.high-contrast .ielades-dialog-content-sub2-downloadgroup2,
.high-contrast .default-secondary-button:hover p,
.high-contrast .ielades-dialog-content-sub2-download,
.high-contrast .data-provide-content-item-background {
  background: var(--background-color);
}

.high-contrast .catalog-header-left-directory-step::after {
  background-color: var(--foreground-color) !important;
}

.high-contrast .overall-input-big:hover,
.high-contrast .data-cat-construction1:hover,
.high-contrast .card-content-detail-tab-tabbutton,
.high-contrast .data-cat-construction:hover,
.high-contrast .tos-modal,
.high-contrast .data-provide-content-item1,
.high-contrast .data-provide-content-item,
.high-contrast .palidzibas-main-right,
.high-contrast .card-content-back,
.high-contrast .three-columns,
.high-contrast .overall-input-dropdown option,
.high-contrast .overall-container,
.high-contrast .register-info-right-profile-info-input,
.high-contrast .register-info-right-profile-info-input-error input,
.high-contrast .overall-input-dropdown-error,
.high-contrast .env-dropdown-menu li,
.high-contrast .data-provide-content div,
.high-contrast .klusti-main-left-sub1-tb,
.high-contrast .results-container div,
.high-contrast .authorize-main-right,
.high-contrast .results-container,
.high-contrast .search-load-more-button:hover,
.high-contrast .klusti-main-left-sub1 th,
.high-contrast .content-main-left,
.high-contrast .register-info-right-cards-item-avatar,
.high-contrast #progress-bar-gray,
.high-contrast .paginate .active,
.high-contrast #progress-bar,
.high-contrast .countries-dropdown,
.high-contrast .card-content-left-owner {
  border-color: var(--foreground-color) !important;
  outline-color: var(--foreground-color) !important;
}

.high-contrast .manaskopas-main .unread {
  border: none;
  outline: none;
  background-color: var(--foreground-color-transparent);
}

.high-contrast .card-content-detail,
.high-contrast .card-content-detail-tab-tabpane-content-format-tag,
.high-contrast .updated-rules-log-out,

.high-contrast .navbar-container,
.high-contrast .lang-dropdown-btn {
  background-color: var(--background-color);
  outline-color: var(--foreground-color);
  border-color: var(--foreground-color);
}

.high-contrast .lang-dropdown-btn,
.high-contrast .lang-dropdown-menu li,
.high-contrast .filter-dropdown-menu li,
.high-contrast .filter-dropdown-btn,
.high-contrast .register-info-right-profile-info-input-error input,
.high-contrast .register-info-right-profile-info-input input,
.high-contrast .updated-rules-log-out,
.high-contrast .manaskopas-main-sub5-btn5:hover,
.high-contrast .register-info-right-profile-info-input input,
.high-contrast .edit-profile,
.high-contrast .authorize-main-left-btngroup input,
.high-contrast .catalog-search-input input,
.high-contrast .searchbar-box-closed input,
.high-contrast .overall-dropdown-selection label,
.high-contrast .register-info-right-rules-note,
.high-contrast .delete-modal,
.high-contrast .klusti-main-left-sub1-tb th,
.high-contrast .card-content-detail-tab-tabbutton button,
.high-contrast .searchbar-box-input-container,
.high-contrast .authorize-main-left-alert,
.high-contrast .searchbar-box,
.high-contrast .api-container,
.high-contrast .overall-databox,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover,
.high-contrast .card-content-detail-tab-tabbutton-tablink {
  outline: 1px solid var(--foreground-color);
  color: var(--foreground-color);
  background: var(--background-color);
}

.high-contrast .searchContainer {
  z-index: 12;
}

.high-contrast .searchbar-box {
  z-index: 13;
  border-radius: 5px;
}

.high-contrast .catalog-search-button:hover .catalog-search-button-text,
.high-contrast .filter-dropdown-btn:hover,
.high-contrast .filter-dropdown-btn:hover span,
.high-contrast .palidzibas-main-accordion-description-subtitle.infertile:hover,
.high-contrast .radio-container .search-modal-button:hover span,
.high-contrast .filter-dropdown-menu li:hover,
.high-contrast .card-content-detail-tab-tabbutton-tablink:hover {
  color: var(--background-color);
  background: var(--foreground-color);
}

.high-contrast .env-dropdown-menu-down button,
.high-contrast .rate-modal {
  outline: 1px solid var(--foreground-color);
}

.high-contrast .edit-profile-modal-content textarea:hover,
.high-contrast .register-info-right-profile-info-input:hover textarea,
.high-contrast .env-dropdown-menu-down button,
.high-contrast .env-dropdown-menu-top button {
  outline: 2px var(--foreground-color) solid;
}

.high-contrast .env-dropdown-menu-down button:hover,
.high-contrast .env-dropdown-menu-top button:hover {
  outline: 2px var(--background-color) solid;
}

.high-contrast .footer-middle-container,
.high-contrast .rc-anchor-invisible-text {
  color: var(--foreground-color) !important;
  background: none;
}

.high-contrast .overall-input-dropdown,
.high-contrast .overall-input-dropdown-error option,
.high-contrast .overall-input-dropdown-error,
.high-contrast .register-info-right-profile-info-input,
.high-contrast .modal-dialog-content-btn:hover .modal-dialog-content-btn-font,
.high-contrast .manaskopas-main-sub5-btn5:hover .manaskopas-main-sub5-font,
.high-contrast .rc-anchor-invisible-hover-hovered span {
  background-color: var(--background-color) !important;
  color: var(--foreground-color) !important;
}

.high-contrast .env-dropdown-btn:hover .env-icon1 {
  filter: grayscale(100%) contrast(60%) saturate(2000%) grayscale(var(--image-grayscale)) invert(var(--image-grayscale)) !important;
}

.high-contrast .rc-anchor-normal-footer {
  display: none !important;
}

.high-contrast .catalog-search-button {
  background-color: var(--background-color);
  outline: var(--foreground-color) 1px solid;
}

.high-contrast .catalog-main-menu-form:hover {
  outline: 3px solid var(--foreground-color);
}

.high-contrast .high-contrast .deleteprofile button,
.high-contrast .filter-dropdown-btn {
  color: var(--foreground-color);
  background-color: var(--background-color);
  border: var(--foreground-color) 1px solid;
  outline: none;
  box-shadow: none;
}

.high-contrast .data-cat-construction,
.high-contrast .data-cat-construction1,
.high-contrast .catalog-main-menu-form,
.high-contrast .catalog-main-filter,
.high-contrast .register-info-right-cards-item-description,
.high-contrast .overall-sub-box,
.high-contrast .card-content-detail-tab-tabpane-header,
.high-contrast .card-content-table th,

.high-contrast .env-dropdown,
.high-contrast .content-main-left {
  border-color: var(--foreground-color) !important;
}

.high-contrast .content-main-left p,
.high-contrast .content-main-left span,
.high-contrast .content-main-left li,
.high-contrast .content-main-left div,
.high-contrast .env-dropdown-btn,
.high-contrast .countries-dropdown div,
.high-contrast .countries-dropdown div:hover,
.high-contrast .overall-input-big,
.high-contrast .register-info-right-next,
.high-contrast .submenu-item,
.high-contrast .authorize-header {
  background: var(--background-color);
  color: var(--foreground-color);
}

.high-contrast .overall-input-big,
.high-contrast .authorize {
  outline: var(--foreground-color) 1px solid;
  background: var(--background-color);
}

.high-contrast .card-content-left-owner-unsubscribe button:hover,
.high-contrast .modal-dialog-content-btn:hover,
.high-contrast .register-info-right-next-profile-button,
.high-contrast .default-secondary-button:hover,
/* .high-contrast .header-setting-down-auth-personal-container2-button4:hover .header-setting-down-auth-personal-container2-menu, */
.high-contrast .header-setting-down-auth-personal-container2-menudrop button,
.high-contrast .authorize button {
  background: var(--background-color);
  box-shadow: none;
  outline: 1px solid var(--foreground-color);
  color: var(--foreground-color);
}

.high-contrast .modal-dialog-content-btn,
.high-contrast .manaskopas-main-sub5-btn5,
.high-contrast .register-info-right-next-profile-button:hover,
.high-contrast .default-secondary-button,
.high-contrast .header-setting-down-auth-personal-container2-menudrop button:hover,
.high-contrast .authorize button:hover {
  color: var(--background-color);
  background: var(--foreground-color);
  box-shadow: none;
  outline: 1px solid var(--background-color);
}

.high-contrast .card-content-left-owner-unsubscribe span,
.high-contrast .modal-dialog-content-btn-font,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title span,
.high-contrast .register-info-left-steps-item .active span,
.high-contrast .header-setting-down-auth-personal-container2-menudrop button:hover p {
  color: var(--background-color);
}

.high-contrast .token-expired-modal,
.high-contrast .authorize-main-left-alert,
.high-contrast .paginate {
  background-color: var(--background-color);
  outline: 1px solid var(--foreground-color);
}

.high-contrast .paginate-numbers:hover {
  color: var(--foreground-color);
  outline: 1px solid var(--foreground-color);
}

.high-contrast .palidzibas-main-accordion .selected,
.high-contrast .paginate-numbers.active,

.high-contrast .register-info-right-cards button,
.high-contrast .active {
  color: var(--background-color);
  background-color: var(--foreground-color);
  outline-color: var(--background-color);
}


.high-contrast .dashboard-main-form2,
.high-contrast .dashboard-main-form {
  border-right: 1px solid var(--foreground-color);
}

.high-contrast .icon-container,
.high-contrast .dashboard-topic-right {
  background: var(--background-color);
  outline: 1px solid var(--foreground-color) !important;
}

.high-contrast .manaskopas-main-tb1-tr1 th {
  background: var(--background-color);
  border-color: var(--foreground-color);
}

.high-contrast .loader {
  filter: grayscale(100%) contrast(70%) sepia(200%) hue-rotate(21deg) saturate(2000%) grayscale(var(--image-grayscale)) invert(var(--image-invert));
}

/* BALTS UZ MELNU */
.high-contrast .header-setting-down-auth-personal-container2-button4:hover img,
.high-contrast .ielades-dialog-content-sub2-downloadgroup2-group-button:hover img,
.high-contrast .navbar-menus-tab .navbar-menus-tab-title:hover .map-link-icon,
.high-contrast .submit-button img,
.high-contrast .card-content-left-owner-unsubscribe img,
.high-contrast .header-setting-down-auth-personal-container2-menu-main-icon,
.high-contrast .palidzibas-main-accordion-description-subtitle:hover img,
.high-contrast .accordion-open-icon-plus,
.high-contrast .card-content-owner-subscribe-button img,
.high-contrast .manaskopas-main-sub5-icon,
.high-contrast .env-dropdown-menu-item:hover .env-icon1,
.high-contrast #progress-next2-third img,
.high-contrast #progress-next2-third:hover img,
.high-contrast .register-info-right-next-profile-button:hover img,
.high-contrast .data-cat-construction-button-icon {
  filter: grayscale(100%) contrast(200%) grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

.high-contrast .overall-input-dropdown-error {
  filter: grayscale(50%) contrast(200%) !important;
  outline: 5px groove var(--foreground-color);
  box-shadow: 0px 0px 15px 8px var(--foreground-color);
}


.high-contrast .navbar-menus-tab-imgs-item:hover .navbar-menus-tab-imgs-item-icon-2,
.high-contrast .navbar-menus-tab-imgs-item:hover .navbar-menus-tab-imgs-item-icon,
.high-contrast .data-cat-construction-button:hover .data-cat-construction-button-icon {
  filter: grayscale(100%) contrast(200%) invert() grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

.high-contrast .card-content-detail-tab-tabbutton,
.high-contrast .box-kopas,
.high-contrast .manaskopas-main,
.high-contrast .overall-input-dropdown:hover,
.high-contrast .iesutne-dialog-content-input,
.high-contrast .palidzibas-main-accordion-title,
.high-contrast .iesutne-dialog-content-dropdown,
.high-contrast .overall-input-dropdown,
.high-contrast .register-info-right-cards button,
.high-contrast .manaskopas-main-tb1 td,
.high-contrast .register-info-right,
.high-contrast .overall-input-dropdown:active,
.high-contrast .token-expired-modal,
.high-contrast .edit-profile-modal-content div textarea:active,
.high-contrast .manaskopas-main-tb1 {
  border-color: var(--foreground-color);
  outline-color: var(--foreground-color);
}

.high-contrast .data-cat-construction-button:hover {
  outline: 1px solid var(--foreground-color);
}

/* FONS UZ BALTU */

.high-contrast.contrast_blackwhite .catalog-main-filter-option-checkbox {
  filter: grayscale() invert() sepia() saturate() contrast() grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation)) brightness(400%);
}

.high-contrast.contrast_yellow .catalog-main-filter-option-checkbox {
  /* do not change any values, this works, only god knows how */
  filter: grayscale(var(--image-grayscale)) invert(var(--image-invert)) invert() sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation)) contrast(200%) grayscale(100%) brightness(1000) invert(86%) sepia(10%) saturate(1038%) hue-rotate(358deg) brightness(102%) contrast(303%) invert() hue-rotate(180deg) invert() invert(1) sepia(1) saturate(10000%) hue-rotate(10deg);
}
.high-contrast.contrast_blackyellow .catalog-main-filter-option-checkbox {
  filter: invert() grayscale() saturate(1000%) sepia(1000%) brightness(300%) invert(86%) sepia(10%) saturate(1038%) hue-rotate(358deg) brightness(102%) contrast(303%) invert(1) sepia(1) saturate(10000%) hue-rotate(10deg) invert() hue-rotate(180deg) sepia(1) saturate(900%) hue-rotate(10deg);
}

/* FONS UZ MELNU */
.high-contrast .default-secondary-button-cancel-icon,
.high-contrast .palidzibas-main-accordion-description-subdescription:hover img,
.high-contrast .palidzibas-main-accordion-description .selected img,
.high-contrast .palidzibas-main-accordion-description-subtitle:hover img,
.high-contrast .authorize-main-left-reg:hover .authorize-main-left-reg-icon {
  filter: invert() brightness(200%) hue-rotate(25deg) saturate(200%) grayscale(var(--image-grayscale)) invert(var(--image-invert)) invert() sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

/* BALTS FONS UZ MELNU UN PAREJAIS DZELTENS */
.high-contrast .navbar-menus-tab-title:hover .test,
.high-contrast .navbar-menus-tab:hover .navbar-menus-tab-title img,
.high-contrast .navbar-menus-tab-title:hover img,
.high-contrast .header-logo-left,
.high-contrast .small-slidebar-slides {
  filter: grayscale(100%) invert() brightness(3.4) contrast(200%) sepia(1000%) saturate(2000%) hue-rotate(0deg) grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

/* PELĒKS UZ DZELTENU */
.high-contrast .label-tag-close,
.high-contrast .checkmark,
.high-contrast .tooltip-img,
.high-contrast .change-black-img-to-gray,
.high-contrast .footer-up-container-image-colored,
.high-contrast .date-filter-dropdown-label,
.high-contrast .header-setting-down-auth-personal-container2-menu img,
.high-contrast .header-setting-down-auth-button:hover .header-setting-down-auth-button-icon,
.high-contrast .register-info-right-next-profile-button img,
.high-contrast .ielades-dialog-content-sub2-downloadgroup2-group-button img,
.high-contrast .header-setting-down-auth-personal-container2-menudrop button img,
.high-contrast .profile-main-left-linkform-link-icon-blue,
.high-contrast #home-icon-button #home-icon,
.high-contrast .profile-main-left-linkform-link-red-icon,
.high-contrast .dashboard-topic-right-top-icon,
.high-contrast .default-secondary-button:hover img,
.high-contrast .register-info-right-rules-checkbox-icon-disabled,
.high-contrast .manaskopas-main-sub5-btn5:hover .manaskopas-main-sub5-icon,
.high-contrast .ielades-dialog-content-sub2-download-file img,
.high-contrast .ielades-dialog-content-sub2-font3 img,
.high-contrast .manaskopas-main-tb1-tr2-td3-right-part2-sub2,
.high-contrast .card-content-left-owner-mark,
.high-contrast .overall-dropdown-selection .icon-close,
.high-contrast .footer-middle-container-social-sites,
.high-contrast .palidzibas-main-accordion-title img,
.high-contrast .ielades-dialog-content-sub1-icon1,
.high-contrast .card-content-back-content,
.high-contrast .catalog-main-filter-dropdown-icon,
.high-contrast .register-info-right-password-progressbar-lines,
.high-contrast .env-dropdown-value,
.high-contrast .navbar-menus-tab-title img,
.high-contrast .close-icon,
.high-contrast .search-load-more-button:hover img,
.high-contrast .palidzibas-main-accordion-description img,
.high-contrast .submit-button:hover img,
.high-contrast .header-setting-down-auth-personal-icon1,
.high-contrast .dashboard-main-icon,
.high-contrast .manaskopas-title-left-stitle-icon,
.high-contrast .footer-middle-container-menus-link-icon,
.high-contrast .overall-databox-devider,
.high-contrast .apliecinajums-title-left-stitle-icon,
.high-contrast .manaskopas-main-tb1-tr2-td1-warm1,
.high-contrast .env-icon1,
.high-contrast .register-info-right-rules-confirm img,
.high-contrast .navbar-menus-tab .navbar-menus-tab-imgs-item-icon-2,
.high-contrast .navbar-menus-tab .navbar-menus-tab-imgs-item-icon,
.high-contrast .footer-up-container-summer,
.high-contrast .page-error-container img,
.high-contrast .register-info-right-rules-checkbox-icon,
.high-contrast .updated-rules-log-out img,
.high-contrast .footer-social-img,
.high-contrast .navbar-searchbar-icon,
.high-contrast .catalog-header-left-directory-icon,
.high-contrast .authorize-main-left-alert-icon,
.high-contrast .authorize-main-left-linkform-link-icon,
.high-contrast .dropdown-arrow,
.high-contrast .klusti-main-left-sub1-tb-icon,
.high-contrast .lang-dropdown-value i,
.high-contrast .register-info-right-profile-mail img,
.high-contrast .register-info-right-profile-mail-error img,
.high-contrast .register-info-right-profile-info img,
.high-contrast .klusti-main-left-sub1-tb-icon,
.high-contrast .results-container .title:hover .arrow,
.high-contrast .close-rules-white,
.high-contrast .authorize-main-left-reg-icon,
.high-contrast .data-provide-content-item-background-text-icon,
.high-contrast .card-content-owner-subscribe-button:hover img,
.high-contrast .dashboard-topic-left-header-icon,
.high-contrast .data-provide-content-item1-link img,
.high-contrast .authorize-header-icon,
.high-contrast .content-main-left-width img,
.high-contrast .item img,
.high-contrast .catalog-header-right-icon,
.high-contrast .register-info-right-next img,
.high-contrast .register-info-right-next-profile-button-disabled img,
.high-contrast .react-datetime-picker svg,
.high-contrast .register-info-right-profile-info-input img,
.high-contrast .card-content-left-owner-update-period img,
.high-contrast .data-set-icon,
.high-contrast .modal-dialog-content-sub2-font3-icon,
.high-contrast .icon-container img,
.high-contrast #progress-next2-third img,
.high-contrast .card-content-detail-tab-tabpane-content-download-item img,
.high-contrast .data-cat-construction-background-text img,
.high-contrast .iesutne-dialog-content-scrollbar a:hover,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover .accordion-open-icon-plus,
.high-contrast .card-content-detail-tab-tabpane-content-accordion-title:hover .accordion-open-icon-minus,
.high-contrast .card-content-detail-tab-tabpane-content-more:hover,
/* zinot par problemu */
.high-contrast .lapas-karte-main-list a:hover,
.high-contrast .about-platform-wrapper a:hover,
.high-contrast .footer-middle-container-social-mail-info a:hover,
.high-contrast .catalog-header-right-text1:hover,
.high-contrast .footer-middle-container-menus-link:hover,
.high-contrast .card-content-left-owner-unsubscribe button:hover span,
.high-contrast .card-content-left-owner-unsubscribe button:hover img,
.high-contrast .profile-main-left-linkform-link-red-icon,
.high-contrast .manaskopas-main-tb1-tr2-td3-right-part1-delete:hover,
.high-contrast .alert-main-icon,
.high-contrast .manaskopas-main-tb1-tr2-td3-right-part2-sub2-delete:hover,
.high-contrast .navbar-menus-tab-title .map-link-icon,
/* .high-contrast .radio-item, */
.high-contrast .no-data img,
.high-contrast .gray-question-mark,
.high-contrast .change-black-img-to-red,
.high-contrast .input-group .label img,
.high-contrast .make-a-copy-span:hover,
.high-contrast .alert-main-icon,
.high-contrast .manaskopas-main-tb1-tr1-th1-sort {
  filter: grayscale(100%) contrast(50%) sepia(200%) hue-rotate(21deg) saturate(20000%) grayscale(var(--image-grayscale)) invert(var(--image-invert));
}


.high-contrast .authorize-main-left-btngroup input:-webkit-autofill,
.high-contrast .authorize-main-left-btngroup input:-webkit-autofill:hover, 
.high-contrast .authorize-main-left-btngroup input:-webkit-autofill:focus, 
.high-contrast .authorize-main-left-btngroup input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--background-color) inset !important;
    -webkit-text-fill-color: var(--foreground-color) !important;
}


.high-contrast .tooltip {
  background-color: var(--background-color);
}

.high-contrast .header-setting-down-auth-personal-icon {
  filter: grayscale(var(--image-grayscale));
}


.high-contrast .content-main-right img {
  /* filter: grayscale(100%) contrast(150%) sepia(1) hue-rotate(14deg) saturate(10%) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation)) !important; */
}

.high-contrast .radio-item {
  outline: 2px solid var(--foreground-color);
}

.high-contrast .radio-container input[type="radio"] {
  background: none;
}

.high-contrast .radio-container input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 40%;
  background-color: var(--foreground-color);
  border-radius: 50%;
}


.high-contrast.contrast_blackyellow .navbar-menus-tab-title:hover .test,
.high-contrast .filter-dropdown-btn:hover img,
.high-contrast .register-info-left-steps-item-icon .active img {
  /* hue rotate to yellow */
  filter: grayscale(100%) saturate(1000%) grayscale(var(--image-grayscale)) invert(var(--image-invert)) sepia(var(--yellow-sepia)) saturate(var(--yellow-saturation));
}

/* .high-contrast .navbar-menus-tab-title:hover .test,
.high-contrast .navbar-menus-tab:hover .navbar-menus-tab-title img,
.high-contrast .navbar-menus-tab-title:hover img {
  filter: grayscale(100%) saturate(200%) invert();
} */

.high-contrast .navbar-menus-tab-title:hover .map-link-icon {
  filter: grayscale(100%) contrast(200%) invert(1);
}

.high-contrast .register-info-right-profile-mail input,
.high-contrast .register-info-right-profile-mail-error input,
.high-contrast .register-info-right-profile-info-input textarea,
.high-contrast .edit-profile-modal-content textarea,
.high-contrast .searchbar-box-closed input,
.high-contrast .register-info-right-profile-info-input,
.high-contrast .register-info-right-profile-info-input-error textarea {
  background-color: var(--background-color);
  color: var(--foreground-color);
  outline-color: var(--background-color);
  border-color: var(--foreground-color);
}

.high-contrast .palidzibas-main-accordion div {
  background-color: var(--background-color);
  color: var(--foreground-color);
  outline-color: var(--background-color);
}