.edit-profile-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: fixed;
  width: 300px;
}

.edit-profile-modal-content {
  color: black;
  border-radius: 4px;
  background: #fff;
  position: relative;
}
